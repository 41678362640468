.eyeoff {
  width: 17px;
  position: absolute;
  top: 32%;
  left: 86%;
}

.connectorPassword {
  margin-right: 1rem;
}

.font-3 {
  font-size: 3em !important;
}

.hidden {
  display: none;
}

.tile-sub {
  font-size: 1.5em;
  position: relative;
  top: 16%;
  line-height: 1.5;
  font-weight: bold;
}

.filter-view {
  height: 35vw;
  overflow-y: scroll;
}

.tile-main {
  font-family: revert;
}

.font-2-7 {
  font-size: 2.7em !important;
}

.text-center {
  text-align: center;
}

.pos-right-10 {
  position: relative;
  right: 10px;
}

.right-section {
  background: #ffffff;
  margin-bottom: 10px;
}

.right-section .upload-button {
  position: relative;
  top: 4%;
  /* right: 35%; */
  margin-bottom: 8%;
}

.upload-alert-text {
  font-size: 1.5em;
  margin-top: 14px;
}

.tile-1 {
  background-color: #4294ff;
}

.tile-2 {
  background-color: #39d779;
}

.tile-3 {
  background-color: #96c93d;
}

.tile-4 {
  background-color: #022060;
}

.with-img-tile .tile-sub {
  top: 0%;
}

.w-150 {
  width: 150px;
}
.w-200 {
  width: 200px;
}
.w-250 {
  width: 250px;
}

.w-400 {
  width: 400px;
}

.w-350 {
  width: 350px;
}

.h-355 {
  height: 355px;
}

.pointer {
  cursor: pointer;
}

.menu-icon {
  width: 25px;
  position: relative !important;
  left: 3px !important;
  margin-right: 10px;
}

.collapsed-logo {
  display: none;
}

.menu-collapsed .main-logo {
  display: none !important;
}

.menu-collapsed .collapsed-logo {
  display: block;
  width: 59px;
  position: relative;
  top: -16px;
  right: 12px;
}

.main-logo {
  width: 180px;
  /* display: hidden; */
}

.elie-icon {
  width: 25px;
  position: relative !important;
  zoom: 1.3;

  margin-right: 19px;
  /* left: -5px; */
  padding-right: 10p;
  width: 15px;
}

.elie-icon + span {
  position: relative;
  left: -14px;
}

.col-green {
  color: #99c93c;
}

.home-tile-section {
  margin: 40px;
}

.home-tile:hover {
  box-shadow: 0 10px 15px rgba(12, 10, 11, 0.3);
  transition: box-shadow 0.3s ease-in-out;
  cursor: pointer;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.zoom-0-9 {
  zoom: 0.9;
}

.color-danger {
  color: red;
}

.loader-css {
  position: absolute;
  text-align: center;
  height: 100%;
  width: 100%;
  background: #ffffff85;
  font-size: 3em;
  z-index: 9;
  padding-top: 12%;
}

.vertical-layout .main-menu.theme-primary .navigation .nav-item.active a {
  background: #040474;
}

.menu-content .active {
  background-color: #040474 !important;
}

.pie-chart .card {
  height: 480px;
}

.menu-text svg {
  color: #a7c715;
}

.login-logo {
  zoom: 1.3;
}

.font-3 {
  font-size: 3em;
}

.alert-heading {
  padding: 3% 2%;
}
.alert p {
  font-size: 1.4em;
  /* padding: 4% 3%; */
}

#type-subtype .card-header,
#type-subtype .card-body {
  padding: 2px;
}

.doc-content .collapse-icon .card-header:after {
  content: none;
}

.accordion-shadow {
  padding: 0.75rem 0.75rem;
}

.collapse-bordered .card .card-header .card-title {
  font-size: 1.1em;
}

.collapse-bordered .card .card-header {
  padding: 0.5rem 0.5rem;
}

#type-subtype .card-header {
  padding: 0.5rem;
  zoom: 0.8;
}

#type-subtype .card-body {
  padding: 1rem;
  text-align: left;
}

#type-subtype .card {
  border-radius: 0.571rem;
  margin: 10px 0;
  border: 0;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1) !important;
}

.pointer {
  cursor: pointer;
}

.sync-btn {
  width: 210px;
  margin-bottom: 2%;
}

.list-icon {
  display: inline;
}

#text-view {
  padding: 2%;
  background: #fff;
  height: 39vw;
  overflow-y: scroll;
  margin-bottom: 10px;
}

#map-legend {
  margin-top: 1%;
  display: grid;
  grid-template-columns: auto auto auto auto auto auto auto auto auto auto;
  float: right;
}

#map-legend .legend {
  display: inline-flex;
  padding-right: 70px;
}

#map-legend .box {
  width: 10px;
  height: 10px;
  position: relative;
  top: 5px;
  margin-right: 10px;
  background: red;
}

#map-legend .color-supplier {
  background: #009688;
}

#map-legend .color-supplier-problem {
  background: #ff0000;
}

#map-legend .color-car {
  background: #3f51b5;
}

#map-legend .color-travel {
  background: #01a9f0;
}

#map-legend .color-life {
  background: #ff9800;
}

#map-legend .color-home {
  background: #9c27b0;
}

#map-legend .color-health {
  background: #46af50;
}

#type-subtype .footer-button {
  /* display: grid; */
}

.change-ui .icon-section {
  display: flex;
}

.change-ui .i-text-left {
  text-align: right;
  padding-right: 10px;
  margin-right: 10px;
  border-right: 1px solid black;
}

.change-ui .icon {
  font-size: 1.6em;
  font-weight: bold;
  text-align: center;
}

.change-ui .i-text-right {
  text-align: left;
}

.color-danger {
  color: red;
}
.color-success {
  color: green;
}
.color-warn {
  color: orange;
}

.change-ui {
  position: absolute;
  right: 10px;
  bottom: 10px;
}

.scroll-400 {
  max-height: 400px;
  overflow-y: scroll;
}

.table-responsive {
  height: 400px;
  overflow-y: scroll;
}

.payable-infobox p {
  margin: 0px;
}

.spacer {
  position: relative;
  top: -5px;
}

#roles-view {
  margin-bottom: 20px;
}

.sp-bold {
  font-weight: bold;
  font-size: 1.3em;
}

.filter-parent {
  position: relative;
}

.filter-icon {
  content: "\e8bd";
  font-family: "feather";
  position: absolute;
  left: 1rem;
  top: 0.85rem;
}

.partner-search,
.customer-search {
  padding: 1.45rem 0rem;
  padding-left: 2.8rem;
  border-radius: 1.428rem;
  border: 1px solid;
  border-color: #d9d9d9;
  max-width: 200px;
}

.partner-sorting,
.customer-sorting {
  border: 1px solid #dae1e7;
  background-color: #fff;
  padding: 1.02rem 0.65rem !important;
  border-radius: 5rem;
}

.inputs {
  border: none;
  background-color: #f8f8f8;
  margin-bottom: 0.3rem;
}
.lowerDiv {
  box-sizing: border-box;
  padding: 9.8px;
  overflow-y: scroll;
  /* height:7rem; */
  border-radius: 5px;
  cursor: default;
  overflow: hidden;
  border: 1px solid rgb(217, 217, 217);
  background-color: #ffffff;
  overflow-wrap: break-word;
}
.para {
  /* z-index:-3; */
  /* focolor:rgb(255, 255, 255); */
  font-size: 13.44px;
  display: inline;
  /* margin:0.2rem */
}

.section-container {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 15px;
  position: relative;
  padding-top: 30px;
}
.close-button {
  position: absolute;
  right: 0;
  top: 0;
}
.action-icon-button {
  padding: 12px;
}
.form-action-button {
  width: 100%;
}
form {
  /* commented height because it was overgrowing out of common loader */
  /* height: 100%; */
}

#add-export-config-form {
  height: auto;
}

#status-dropdown::before {
  bottom: -0.6rem !important ;
  top: auto !important ;
  border: none !important;
  background: #ffffff !important;
}

.action-button {
  padding: 0.9rem;
}

.padding-three-fourth-rem {
  padding: 0.75rem;
}

.input-group-prepend select {
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
}

fieldset {
  border: solid 1px;
}

.story-step-container,
.slots-categorical-values-container,
.intent-questions-container,
.intent-patterns-container,
.slots-by-role-container {
  padding: 16px;
}

.custom-cookie-consent-container {
  z-index: 9999 !important;
}
.custom-cookie-consent-content {
  flex: 1 0 auto !important;
  margin-bottom: 0 !important;
}

.color-preview-addon-input {
  width: 37.6px;
  padding: 0 4px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.datetime-local-input {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  height: 37.4px;
  padding: 0 0.5rem 0 0.5rem;
}
